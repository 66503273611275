import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  "en-US": {
    translation: {
      addHeadline: "Add {{item}}",
      addItemText: "Enter {{item}}",
      addTextfield: "Enter new {{item}}",
      editHeadline: "Edit {{item}}",
      inventory: "Inventorynumber",
      location: "Location",
      manufacturer: "Manufacturer",
      manufacturerSerial: "Serialnumber",
      maxChars: "{{numChars}} characters max.",
      model: "Model",
      notCorrect: "ATTENTION: Data is not valid anymore!",
      purchaseDate: "Purchase Date",
      SortLabel: "Sort by '{{item}}'",
      Status: "State",
      Statuses: "States",
      supplier: "Supplier",
      type: "Type",
      warnUnreadMessages:
        "You have {{numMessages}} unread messages in your mailbox.",
      warnUnreadMessage: "You have 1 unread message in your mailbox.",
    },
  },

  // For use in modules:
  // import { useTranslation } from "react-i18next";
  // const { t } = useTranslation();

  "cs-CZ": {
    translation: {
      "Admin tag may not be reassigned.":
        "Značku správce nelze znovu přiřadit.",
      "Administration menu": "Nabídka Administrace",
      Assign: "Přiřazení",
      "Assign NFC-tags to users": "Přiřazení značek NFC uživatelům",

      Available: "Dostupné na",
      "Back from break": "Zpět z přestávky",
      "Begin break": "Začátek přestávky",
      Cancel: "Zrušit",
      Close: "Zavřít",
      "3. Confirm": "3. Potvrďte",
      "End break": "Konec přestávky",
      Enter: "Vstupte na",
      Exit: "Exit",
      "Forgot to start break": "Zapomněl jsem začít přestávku",
      Location: "Umístění",
      "No available time clock codes found.":
        "Nebyly nalezeny žádné dostupné kódy časových hodin.",
      "Not recognized": "Neuznáno",
      No: "Ne",
      "No Tag assigned": "Žádný přidělený štítek",
      "or present transponder": "nebo současný transpondér",
      Planned: "Plánované",
      "Please contact the administrator.": "Kontaktujte prosím správce.",
      "Please present transponder": "Předložte transpondér",
      "Please touch the screen": "Dotkněte se obrazovky",
      "2. Assign NFC tags": "2. Přiřazení značek NFC",
      "Register terminal": "Registrační terminál",
      "Requested / Approved": "Požadované / Schváleno",
      Save: "Uložit",
      "Select a reason": "Vyberte důvod",
      "1. Select user": "1. Vyberte uživatele",
      "Show information": "Zobrazit informace",
      "Somura Time Tracking": "Somura Sledování času",
      "Start break": "Začátek přestávky",
      "Tag already assigned to ": "Značka již byla přiřazena ",
      "Tags successfully assigned.": "Úspěšně přiřazené značky.",
      Taken: "Převzato",
      "Tap on OK-button to unregister this terminal.":
        "Klepnutím na tlačítko OK zrušíte registraci tohoto terminálu.",
      "Terminal is locked!": "Terminál je uzamčen!",
      "Terminal is offline!": "Terminál je offline!",
      "Terminal not registered": "Terminál není registrován",
      "Terminal is registered": "Terminál je registrován",
      "This terminal is already registered.":
        "Tento terminál je již zaregistrován.",
      "This month": "Tento měsíc",
      "This week": "Tento týden",
      "This year": "Tento rok",
      Today: "Dnes",
      "Unregister terminal": "Zrušení registrace terminálu",
      Vacation: "Dovolená",
      "Working times": "Pracovní doba",
      Yes: "Ano",
    },
  },

  "de-DE": {
    translation: {
      "Admin tag may not be reassigned.":
        "Der Administrations-Tag darf nicht neu zugewiesen werden.",
      "Administration menu": "Administrations Menü",
      Assign: "Zuordnen",
      "Assign NFC-tags to users": "NFC-Tags Personen zuordnen",
      Available: "Verfügbar",
      "Back from break": "Zurück aus Pause",
      "Begin break": "Pause beginnen",
      Cancel: "Abbrechen",
      Close: "Schließen",
      "3. Confirm": "3. Bestätigen",
      "End break": "Pause beendet",
      Enter: "Kommen",
      Exit: "Gehen",
      "Forgot to start break": "Pausen-Start vergessen",
      Location: "Standort",
      "No available time clock codes found.": "Keine Terminal-Codes verfügbar.",
      "Not recognized": "Nicht erkannt",
      "Not registered": "Nicht registriert",
      No: "Nein",
      "No Tag assigned": "Kein NFC-Tag zugeordnet",
      "Not yet working": "Noch nicht verfügbar",
      "or present transponder": "oder NFC-Tag einlesen",
      Planned: "Geplant",
      "Please contact the administrator.":
        "Bitte wenden Sie sich an einen Administrator.",
      "Please present transponder": "Bitte NFC-Tag einlesen",
      "Please touch the screen": "Bitte den Bildschirm berühren",
      "2. Assign NFC tags": "2. NFC-Tags zuordnen",
      "Register terminal": "Terminal registrieren",
      "Requested / Approved": "Beantragt / Genehmigt",
      Save: "Speichern",
      "Select a reason": "Wählen Sie einen Grund",
      "1. Select user": "1. Person wählen",
      "Show information": "Informationen anzeigen",
      "Somura Time Tracking": "Somura Zeiterfassung",
      "Start break": "Pause beginnen",
      "Tag already assigned to ": "Tag bereits zugewiesen an ",
      "Tags successfully assigned.": "Tags erfolgreich zugewiesen.",
      Taken: "Genommen",
      "Tap on OK-button to unregister this terminal.":
        "Tippen Sie auf OK um die Registrierung dieses Terminals aufzuheben.",
      "Terminal is locked!": "Terminal ist gesperrt!",
      "Terminal is offline!": "Terminal ist offline!",
      "Terminal not registered": "Terminal nicht registriert",
      "Terminal is registered": "Terminal ist registriert",
      "This terminal is already registered.":
        "Dieses Terminal ist bereits registriert.",
      "This month": "Dieser Monat",
      "This week": "Diese Woche",
      "This year": "Dieses Jahr",
      Today: "Heute",
      "Unregister terminal": "Registrierung aufheben",
      Vacation: "Urlaub",
      warnUnreadMessages:
        "Sie haben {{numMessages}} ungelesene Nachrichten in Ihrem Posteingang.",
      warnUnreadMessage:
        "Sie haben 1 ungelesene Nachricht in Ihrem Posteingang.",
      "Working times": "Arbeitszeiten",
      Yes: "Ja",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en-US",
  fallbackLng: "en-US",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  keySeparator: ">",
  nsSeparator: "|",
});
export default i18n;
